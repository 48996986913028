import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, Chip, Avatar
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import SnackbarNotification from "../../../components/SnackbarNotification";
import InlineMedia from "../../../components/inline-media/InlineMedia";
import TabPanel from "../../components/TabPanel";
import {useTranslation} from "react-i18next";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    ApprovalQuery,
    NewApprovalQuery,
    useApprovalQuery,
    useCreateApprovalMutation,
    useNewApprovalQuery,
    useUpdateApprovalMutation,
    CreateApprovalMutation,
    useInlineMediaUploadApprovalMutation, InlineMediaUploadApprovalMutation, useResetApprovalMutation
} from "../../../graphql/settings/approval/approval.graphql-gen";

// Types
import {MediaItemType} from "../../../types/inlineMedia";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import GeneralInfoApproval from "./fieldGroups/GeneralInfoApproval";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import england from "../../../assets/img/england.svg";
import germany from "../../../assets/img/germany.svg";
import france from "../../../assets/img/france.svg";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
});

const Approval: React.FC = () => {
    let title: string = 'Neue Marke'
    let dataObj: any = {};
    const params = useParams();
    let id = params.id ? params.id : '';
    const inlineModels: string[] = ['image'];
    const navigate = useNavigate();
    const model: string = 'Approval';
    let fromMediaCenter: boolean = false;
    const location: any = useLocation();
    const { i18n, t } = useTranslation();

    // Queries
    const {data: data, error: error, loading: loading} = useApprovalQuery({
        variables: {id, model},
        skip: id === '',
        onCompleted: (data: ApprovalQuery) => {
            setImage(data.approval.image);
            setImageItems(data.approval.image?.items);
            if (data.approval && data.approval.approval) {
                setState(data.approval.approval);
                setInicialValue({
                    "data": data.approval.approval,
                    "images": data.approval.image?.items
                });
            }
            setUserId(data.user.user?.id);
        }
    });
    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewApprovalQuery({
        variables: {
            fields: inlineModels,
        },
        skip: id !== '',
        onCompleted: (data: NewApprovalQuery) => {
            setState({});
            setInicialValue({});
            setUserId(data.user.user?.id);
            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'image':
                            setImage(item.inlineMedia);
                            break;
                    }
                })
            }
        }
    });
    const [updateApproval] = useUpdateApprovalMutation();
    const [createApproval] = useCreateApprovalMutation();
    const [inlineMediaUpload] = useInlineMediaUploadApprovalMutation();
    const [resetApproval] =useResetApprovalMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [image, setImage] = useState<any>(dataObj.approval ? dataObj.approval.image : {});
    const [imageItems, setImageItems] = useState<any>(dataObj.approval ? dataObj.approval.image.items : []);
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(location.state?.fromMediaCenter ? 1 : 0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [userId, setUserId] = useState<any>();
    const [lang, setLang] = useState<string>('');
    const [isLang, setIsLang] = useState<boolean>(false);

    // Set loading
    if (loading || loadingNew || loader) {
        return <Loader/>
    }

    // Handle error
    if (error || errorNew) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data || dataNew) {
        dataObj = data || dataNew
    }

    // Set title
    if (dataObj.approval && dataObj.approval.approval) {
        title = dataObj.approval.approval.title;
    }

    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveApproval = (fieldName?: any, fieldType?: any, isMultiple?: any) => {
        if (id !== '') {
            updateApprovalMutation(state, fieldName, fieldType, isMultiple);
        } else {
            createApprovalMutation(state, fieldName, fieldType, isMultiple);
        }
        setInicialValue({
            "data": state, "images": imageItems
        });
    }

    // Update approval mutation
    const updateApprovalMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?:boolean) => {
        updateApproval({
            variables: {
                model,
                id,
                data,
                image: imageItems,
                removedMedia,
                temp: fromMediaCenter
            }
        }).then(r => {
            setUpdated(true)
            setRemovedMedia([])

            setTimeout(() => {
                setUpdated(false);
            }, 3000)
            if(fromMediaCenter){
                navigate(`/mediacenter/`, {replace: true, state:{fieldName, fieldType, isMultiple, modelName: model, modelId: id}})
            }

        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        });
    }

    // Create approval mutation
    const createApprovalMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?:boolean) => {
        createApproval({
            variables: {
                model,
                data,
                user: userId,
                image: imageItems,
                removedMedia,
                temp: fromMediaCenter
            },
        })
            .then((res: FetchResult<CreateApprovalMutation>) => {
                if (res.data && res.data.createApproval.approval) {
                    setUpdated(true);
                    if(fromMediaCenter){
                        navigate(`/mediacenter/`, {replace: true, state:{fieldName, fieldType, isMultiple, modelName: model, modelId: res.data.createApproval.approval.id}})
                    }else{
                        navigate(`/settings/benefits/${res.data.createApproval.approval.id}`, {replace: true})
                    }

                }
            })
            .catch(error => {
                return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        resetApproval({
            variables: {
                model,
                id
            }
        }).then(r => {
            if(!isLang){
                navigate(`/settings/benefits`, {replace: true})
            }else{
                navigate(`/settings/benefits/${lang}/${id}`, {replace: true})
            }
        }).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        });
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, {"data": state, "images": imageItems});
        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, {"data": state, "images": imageItems});
        i18n.changeLanguage(lang);
        setLang(lang);
        setIsLang(true);
        if (hasChanged) {
            setOpen(true);
        } else {
            navigate(`/settings/benefits/${lang}/${id}`, {replace: true})
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }


    const onAddImageHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'image',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadApprovalMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setImageItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Fehler, bitte versuchen Sie es später noch einmal." open={true} type={'error'}/>
        })
    }

    // Update media
    const onImageUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = imageItems.findIndex((item: MediaItemType) => item.id === updateData.id);
        const newItems = [...imageItems];
        newItems[foundIndex] = updateData;
        setImageItems(newItems)
    }

    // Remove media
    const onImageRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setImageItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onImageBrowseHandler =() => {
        fromMediaCenter = true;
        onSaveApproval('image', image.fieldType, image.isMultiple);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;


        if (event.target) {
            fieldName = event.target.name;
            value = event.target.value;
        }

        if (field) {
            fieldName = field
            value = event
        }

        // Update select value
        setState({...state, [fieldName]: value});
    };

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <Helmet title="Benefit"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveApproval}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wraper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
                                                    <Tab label="Allgemein" {...tabsProps(0)} />
                                                    <Tab label="Medien" {...tabsProps(1)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoApproval
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Bild
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={imageItems}
                                                        fieldType={image.fieldType}
                                                        allowedExtensions={image.allowedExtensions}
                                                        isMultiple={image.isMultiple}
                                                        onAdd={onAddImageHandler}
                                                        onUpdate={onImageUpdateHandler}
                                                        onDelete={onImageRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onImageBrowseHandler}
                                                        languages={image.languages}

                                                    />
                                                </TabPanel>

                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Erfolgreich gespeichert" open={updated}/>
            }
            <ConfirmationDialog
                message="Sind Sie sicher, dass Sie gehen wollen, ohne zu speichern?"
                title="Seite verlassen"
                open={open}
                button="Bestätigen"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default Approval;
