import * as Types from '../../generated/types.graphql-gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** Json scalar */
  Json: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
};

export type Additional = {
  __typename?: 'Additional';
  categories?: Maybe<Array<Maybe<Category>>>;
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<User>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  options?: Maybe<Array<Maybe<InlineAdditionalOptions>>>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type AdditionalArray = {
  __typename?: 'AdditionalArray';
  additional?: Maybe<AdditionalData>;
  options?: Maybe<Array<Maybe<InlineAdditionalOptions>>>;
};

export type AdditionalData = {
  __typename?: 'AdditionalData';
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type Approval = {
  __typename?: 'Approval';
  crUser?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  linkDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type ApprovalArray = {
  __typename?: 'ApprovalArray';
  approval?: Maybe<ApprovalData>;
  image?: Maybe<InlineMedia>;
};

export type ApprovalData = {
  __typename?: 'ApprovalData';
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  linkDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type Article = {
  __typename?: 'Article';
  articleNo?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  platforms?: Maybe<Array<Maybe<Platform>>>;
  product?: Maybe<Product>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type ArticleArray = {
  __typename?: 'ArticleArray';
  article?: Maybe<ArticleData>;
  files?: Maybe<InlineMedia>;
  images?: Maybe<InlineMedia>;
};

export type ArticleData = {
  __typename?: 'ArticleData';
  articleNo?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  product?: Maybe<Scalars['ID']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type AuthUser = {
  __typename?: 'AuthUser';
  accessToken?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Json']>;
  expDate?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Category = {
  __typename?: 'Category';
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<User>;
  customerPartGroupsEvaluation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  exportToErp?: Maybe<Scalars['Boolean']>;
  exportToWebshop?: Maybe<Scalars['Boolean']>;
  hasChildElements?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  indentation?: Maybe<Scalars['String']>;
  parent?: Maybe<Category>;
  parentShop?: Maybe<Category>;
  platforms?: Maybe<Array<Maybe<Platform>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  ratingFromPercentageValue?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  valuationFromTurnover?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type CategoryArray = {
  __typename?: 'CategoryArray';
  category?: Maybe<CategoryData>;
  contentElements?: Maybe<Array<Maybe<InlineContentElement>>>;
  files?: Maybe<InlineMedia>;
  headerImages?: Maybe<InlineMedia>;
  images?: Maybe<InlineMedia>;
  multiElement?: Maybe<CategoryData>;
};

export type CategoryData = {
  __typename?: 'CategoryData';
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  customerPartGroupsEvaluation?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  exportToErp?: Maybe<Scalars['Boolean']>;
  exportToWebshop?: Maybe<Scalars['Boolean']>;
  hasChildElements?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  indentation?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ID']>;
  parentShop?: Maybe<Scalars['ID']>;
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  ratingFromPercentageValue?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  valuationFromTurnover?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  additional?: Maybe<Scalars['String']>;
  additionalDe?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityDe?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['String']>;
  companySolutions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companyType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  countries?: Maybe<Array<Maybe<Country>>>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<State>>>;
  street?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type CompanyArray = {
  __typename?: 'CompanyArray';
  company?: Maybe<CompanyData>;
  logo?: Maybe<InlineMedia>;
};

export type CompanyData = {
  __typename?: 'CompanyData';
  additional?: Maybe<Scalars['String']>;
  additionalDe?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityDe?: Maybe<Scalars['String']>;
  companyNo?: Maybe<Scalars['String']>;
  companySolutions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companyType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  countries?: Maybe<Array<Maybe<Scalars['ID']>>>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<Scalars['ID']>>>;
  street?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ConfHeader = {
  __typename?: 'ConfHeader';
  id?: Maybe<Scalars['ID']>;
  model?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type DangerousGood = {
  __typename?: 'DangerousGood';
  active?: Maybe<Scalars['String']>;
  alignmentArrowsDangerLabels?: Maybe<Scalars['String']>;
  bfx?: Maybe<Scalars['String']>;
  class3dangerLabel?: Maybe<Scalars['String']>;
  class8dangerLabel?: Maybe<Scalars['String']>;
  class9dangerLabel?: Maybe<Scalars['String']>;
  class51dangerLabel?: Maybe<Scalars['String']>;
  classGift?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<User>;
  dangerousLabel8?: Maybe<Scalars['String']>;
  dangerousLabel9?: Maybe<Scalars['String']>;
  dangerousLabel10?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  environmentalDangers?: Maybe<Scalars['String']>;
  fishDangerLabel?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lvsLastActivity?: Maybe<Scalars['String']>;
  outerPackaging?: Maybe<Scalars['String']>;
  packaging?: Maybe<Scalars['String']>;
  pointsFactor?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  technicalDescription?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  tunnelCode?: Maybe<Scalars['String']>;
};

export type DangerousGoodArray = {
  __typename?: 'DangerousGoodArray';
  dangerousgood?: Maybe<DangerousGoodData>;
  image?: Maybe<InlineMedia>;
};

export type DangerousGoodData = {
  __typename?: 'DangerousGoodData';
  active?: Maybe<Scalars['String']>;
  alignmentArrowsDangerLabels?: Maybe<Scalars['String']>;
  bfx?: Maybe<Scalars['String']>;
  class3dangerLabel?: Maybe<Scalars['String']>;
  class8dangerLabel?: Maybe<Scalars['String']>;
  class9dangerLabel?: Maybe<Scalars['String']>;
  class51dangerLabel?: Maybe<Scalars['String']>;
  classGift?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  dangerousLabel8?: Maybe<Scalars['String']>;
  dangerousLabel9?: Maybe<Scalars['String']>;
  dangerousLabel10?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  environmentalDangers?: Maybe<Scalars['String']>;
  fishDangerLabel?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  lvsLastActivity?: Maybe<Scalars['String']>;
  outerPackaging?: Maybe<Scalars['String']>;
  packaging?: Maybe<Scalars['String']>;
  pointsFactor?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  technicalDescription?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  tunnelCode?: Maybe<Scalars['String']>;
};

export type DataAdditional = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  code?: InputMaybe<Scalars['String']>;
  crUser?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  sorting?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataAdditionalOption = {
  id?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataApproval = {
  clearDescription?: InputMaybe<Scalars['Boolean']>;
  clearDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearImage?: InputMaybe<Scalars['Boolean']>;
  clearLink?: InputMaybe<Scalars['Boolean']>;
  clearLinkDe?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  crUser?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<Scalars['String']>;
  linkDe?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataArticle = {
  articleNo?: InputMaybe<Scalars['String']>;
  clearDescription?: InputMaybe<Scalars['Boolean']>;
  clearDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearPlatforms?: InputMaybe<Scalars['Boolean']>;
  clearProduct?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  crUser?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  product?: InputMaybe<Scalars['ID']>;
  sorting?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataCategory = {
  clearContentElements?: InputMaybe<Scalars['Boolean']>;
  clearCustomerPartGroupsEvaluation?: InputMaybe<Scalars['Boolean']>;
  clearDescription?: InputMaybe<Scalars['Boolean']>;
  clearDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearExportToErp?: InputMaybe<Scalars['Boolean']>;
  clearExportToWebshop?: InputMaybe<Scalars['Boolean']>;
  clearFiles?: InputMaybe<Scalars['Boolean']>;
  clearHasChildElements?: InputMaybe<Scalars['Boolean']>;
  clearHeaderImages?: InputMaybe<Scalars['Boolean']>;
  clearImages?: InputMaybe<Scalars['Boolean']>;
  clearIndentation?: InputMaybe<Scalars['Boolean']>;
  clearParent?: InputMaybe<Scalars['Boolean']>;
  clearParentShop?: InputMaybe<Scalars['Boolean']>;
  clearPlatforms?: InputMaybe<Scalars['Boolean']>;
  clearProducts?: InputMaybe<Scalars['Boolean']>;
  clearRatingFromPercentageValue?: InputMaybe<Scalars['Boolean']>;
  clearSeoDescription?: InputMaybe<Scalars['Boolean']>;
  clearSeoDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearSeoKeywords?: InputMaybe<Scalars['Boolean']>;
  clearSeoKeywordsDe?: InputMaybe<Scalars['Boolean']>;
  clearSeoTitle?: InputMaybe<Scalars['Boolean']>;
  clearSeoTitleDe?: InputMaybe<Scalars['Boolean']>;
  clearShortDescription?: InputMaybe<Scalars['Boolean']>;
  clearShortDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['Boolean']>;
  clearSubtitle?: InputMaybe<Scalars['Boolean']>;
  clearSubtitleDe?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  clearType?: InputMaybe<Scalars['Boolean']>;
  clearValuationFromTurnover?: InputMaybe<Scalars['Boolean']>;
  clearVideo?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  crUser?: InputMaybe<Scalars['ID']>;
  customerPartGroupsEvaluation?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  exportToErp?: InputMaybe<Scalars['Boolean']>;
  exportToWebshop?: InputMaybe<Scalars['Boolean']>;
  hasChildElements?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  indentation?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ID']>;
  parentShop?: InputMaybe<Scalars['ID']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  products?: InputMaybe<Array<InputMaybe<DataProduct>>>;
  ratingFromPercentageValue?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoDescriptionDe?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoKeywordsDe?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  seoTitleDe?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  shortDescriptionDe?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitleDe?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  valuationFromTurnover?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
};

export type DataCompany = {
  additional?: InputMaybe<Scalars['String']>;
  additionalDe?: InputMaybe<Scalars['String']>;
  building?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cityDe?: InputMaybe<Scalars['String']>;
  clearAdditional?: InputMaybe<Scalars['Boolean']>;
  clearAdditionalDe?: InputMaybe<Scalars['Boolean']>;
  clearBuilding?: InputMaybe<Scalars['Boolean']>;
  clearCity?: InputMaybe<Scalars['Boolean']>;
  clearCityDe?: InputMaybe<Scalars['Boolean']>;
  clearCompanySolutions?: InputMaybe<Scalars['Boolean']>;
  clearCompanyType?: InputMaybe<Scalars['Boolean']>;
  clearCountries?: InputMaybe<Scalars['Boolean']>;
  clearEmail?: InputMaybe<Scalars['Boolean']>;
  clearFax?: InputMaybe<Scalars['Boolean']>;
  clearLatitude?: InputMaybe<Scalars['Boolean']>;
  clearLogo?: InputMaybe<Scalars['Boolean']>;
  clearLongitude?: InputMaybe<Scalars['Boolean']>;
  clearMobile?: InputMaybe<Scalars['Boolean']>;
  clearPerson?: InputMaybe<Scalars['Boolean']>;
  clearPhone?: InputMaybe<Scalars['Boolean']>;
  clearStates?: InputMaybe<Scalars['Boolean']>;
  clearStreet?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  clearWebsite?: InputMaybe<Scalars['Boolean']>;
  clearZip?: InputMaybe<Scalars['Boolean']>;
  companyNo?: InputMaybe<Scalars['String']>;
  companySolutions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  companyType?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  countries?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  street?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type DataContentElement = {
  description?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  label?: InputMaybe<Scalars['String']>;
  labelDe?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  linkDe?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleDe?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
  videoDe?: InputMaybe<Scalars['String']>;
};

export type DataDangerousGood = {
  active?: InputMaybe<Scalars['String']>;
  alignmentArrowsDangerLabels?: InputMaybe<Scalars['String']>;
  bfx?: InputMaybe<Scalars['String']>;
  class3dangerLabel?: InputMaybe<Scalars['String']>;
  class8dangerLabel?: InputMaybe<Scalars['String']>;
  class9dangerLabel?: InputMaybe<Scalars['String']>;
  class51dangerLabel?: InputMaybe<Scalars['String']>;
  classGift?: InputMaybe<Scalars['String']>;
  clearActive?: InputMaybe<Scalars['Boolean']>;
  clearAlignmentArrowsDangerLabels?: InputMaybe<Scalars['Boolean']>;
  clearBfx?: InputMaybe<Scalars['Boolean']>;
  clearClass3dangerLabel?: InputMaybe<Scalars['Boolean']>;
  clearClass8dangerLabel?: InputMaybe<Scalars['Boolean']>;
  clearClass9dangerLabel?: InputMaybe<Scalars['Boolean']>;
  clearClass51dangerLabel?: InputMaybe<Scalars['Boolean']>;
  clearClassGift?: InputMaybe<Scalars['Boolean']>;
  clearCode?: InputMaybe<Scalars['Boolean']>;
  clearDangerousLabel8?: InputMaybe<Scalars['Boolean']>;
  clearDangerousLabel9?: InputMaybe<Scalars['Boolean']>;
  clearDangerousLabel10?: InputMaybe<Scalars['Boolean']>;
  clearDescription?: InputMaybe<Scalars['Boolean']>;
  clearDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearEnvironmentalDangers?: InputMaybe<Scalars['Boolean']>;
  clearFishDangerLabel?: InputMaybe<Scalars['Boolean']>;
  clearImage?: InputMaybe<Scalars['Boolean']>;
  clearLvsLastActivity?: InputMaybe<Scalars['Boolean']>;
  clearOuterPackaging?: InputMaybe<Scalars['Boolean']>;
  clearPackaging?: InputMaybe<Scalars['Boolean']>;
  clearPointsFactor?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['Boolean']>;
  clearTechnicalDescription?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  clearTunnelCode?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  crUser?: InputMaybe<Scalars['ID']>;
  dangerousLabel8?: InputMaybe<Scalars['String']>;
  dangerousLabel9?: InputMaybe<Scalars['String']>;
  dangerousLabel10?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  environmentalDangers?: InputMaybe<Scalars['String']>;
  fishDangerLabel?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  lvsLastActivity?: InputMaybe<Scalars['String']>;
  outerPackaging?: InputMaybe<Scalars['String']>;
  packaging?: InputMaybe<Scalars['String']>;
  pointsFactor?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  technicalDescription?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  tunnelCode?: InputMaybe<Scalars['String']>;
};

export type DataFile = {
  altName?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type DataFolder = {
  id?: InputMaybe<Scalars['ID']>;
  parent?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataImport = {
  clearFile?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['Boolean']>;
  crUser?: InputMaybe<Scalars['ID']>;
  cronSchedule?: InputMaybe<Scalars['String']>;
  enclosure?: InputMaybe<Scalars['String']>;
  entity?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  imagesFileDirectory?: InputMaybe<Scalars['String']>;
  importSource?: InputMaybe<Scalars['String']>;
  separator?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  validImportFile?: InputMaybe<Scalars['Boolean']>;
};

export type DataLanguage = {
  clearCode?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataMedia = {
  altTitle?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  thumbnail?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataModel = {
  fieldName?: InputMaybe<Scalars['String']>;
  fieldType?: InputMaybe<Scalars['String']>;
  fromMediaCenter?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  isMultiple?: InputMaybe<Scalars['Boolean']>;
  modelId?: InputMaybe<Scalars['ID']>;
  modelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  modelName?: InputMaybe<Scalars['String']>;
  submodelId?: InputMaybe<Scalars['String']>;
  submodelName?: InputMaybe<Scalars['String']>;
};

export type DataOption = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  textDe?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataPlatform = {
  clearCode?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  crUser?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataProduct = {
  accessories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  additionals?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  approvals?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  approvalsNote?: InputMaybe<Scalars['String']>;
  approvalsNoteDe?: InputMaybe<Scalars['String']>;
  articles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  assortmentItems?: InputMaybe<Scalars['String']>;
  catalogItems?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoriesShop?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  clearAccessories?: InputMaybe<Scalars['Boolean']>;
  clearAdditionals?: InputMaybe<Scalars['Boolean']>;
  clearApprovals?: InputMaybe<Scalars['Boolean']>;
  clearApprovalsNote?: InputMaybe<Scalars['Boolean']>;
  clearApprovalsNoteDe?: InputMaybe<Scalars['Boolean']>;
  clearBeaFiles?: InputMaybe<Scalars['Boolean']>;
  clearCadFiles?: InputMaybe<Scalars['Boolean']>;
  clearCategories?: InputMaybe<Scalars['Boolean']>;
  clearCategoriesShop?: InputMaybe<Scalars['Boolean']>;
  clearCompanies?: InputMaybe<Scalars['Boolean']>;
  clearContentElements?: InputMaybe<Scalars['Boolean']>;
  clearDangerousGoods?: InputMaybe<Scalars['Boolean']>;
  clearDescription?: InputMaybe<Scalars['Boolean']>;
  clearDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearFiles?: InputMaybe<Scalars['Boolean']>;
  clearGenProductPosting?: InputMaybe<Scalars['Boolean']>;
  clearHeaderImages?: InputMaybe<Scalars['Boolean']>;
  clearImages?: InputMaybe<Scalars['Boolean']>;
  clearItemTrackingCode?: InputMaybe<Scalars['Boolean']>;
  clearMoaFiles?: InputMaybe<Scalars['Boolean']>;
  clearParent?: InputMaybe<Scalars['Boolean']>;
  clearPlatforms?: InputMaybe<Scalars['Boolean']>;
  clearProFiles?: InputMaybe<Scalars['Boolean']>;
  clearProductType?: InputMaybe<Scalars['Boolean']>;
  clearPublishDate?: InputMaybe<Scalars['Boolean']>;
  clearRxpFiles?: InputMaybe<Scalars['Boolean']>;
  clearSchFiles?: InputMaybe<Scalars['Boolean']>;
  clearSdbFiles?: InputMaybe<Scalars['Boolean']>;
  clearSdoFiles?: InputMaybe<Scalars['Boolean']>;
  clearSeoDescription?: InputMaybe<Scalars['Boolean']>;
  clearSeoDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearSeoKeywords?: InputMaybe<Scalars['Boolean']>;
  clearSeoKeywordsDe?: InputMaybe<Scalars['Boolean']>;
  clearSeoTitle?: InputMaybe<Scalars['Boolean']>;
  clearSeoTitleDe?: InputMaybe<Scalars['Boolean']>;
  clearShortDescription?: InputMaybe<Scalars['Boolean']>;
  clearShortDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['String']>;
  clearSubtitle?: InputMaybe<Scalars['Boolean']>;
  clearSubtitleDe?: InputMaybe<Scalars['Boolean']>;
  clearSupply?: InputMaybe<Scalars['Boolean']>;
  clearTdbFiles?: InputMaybe<Scalars['Boolean']>;
  clearTechnicalSpecifications?: InputMaybe<Scalars['Boolean']>;
  clearTechnicalSpecificationsNote?: InputMaybe<Scalars['Boolean']>;
  clearTechnicalSpecificationsNoteDe?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  clearUnitOfMeasure?: InputMaybe<Scalars['Boolean']>;
  clearUpdatedAt?: InputMaybe<Scalars['Boolean']>;
  clearVidFiles?: InputMaybe<Scalars['Boolean']>;
  clearVideo?: InputMaybe<Scalars['Boolean']>;
  clearVoltageFrom?: InputMaybe<Scalars['Boolean']>;
  clearVoltageTo?: InputMaybe<Scalars['Boolean']>;
  clearZeiFiles?: InputMaybe<Scalars['Boolean']>;
  companies?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  crUser?: InputMaybe<Scalars['ID']>;
  creditArticleNumber?: InputMaybe<Scalars['String']>;
  customerItems?: InputMaybe<Scalars['String']>;
  dangerousGoods?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  eanCode?: InputMaybe<Scalars['String']>;
  exportToErp?: InputMaybe<Scalars['Boolean']>;
  exportToWebshop?: InputMaybe<Scalars['Boolean']>;
  exportWeb?: InputMaybe<Scalars['Boolean']>;
  genProductPosting?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  grossWeight?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  itemTrackingCode?: InputMaybe<Scalars['String']>;
  limitedQuantity?: InputMaybe<Scalars['String']>;
  lvsStockItems?: InputMaybe<Scalars['String']>;
  netWeight?: InputMaybe<Scalars['String']>;
  orderQuantity?: InputMaybe<Scalars['String']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  price?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  publishDate?: InputMaybe<Scalars['String']>;
  quantityPerPackaging?: InputMaybe<Scalars['String']>;
  quantityPerPallet?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoDescriptionDe?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoKeywordsDe?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  seoTitleDe?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  shortDescriptionDe?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  specialRegulation?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitleDe?: InputMaybe<Scalars['String']>;
  supplierItemName?: InputMaybe<Scalars['String']>;
  supply?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  technicalSpecificationsNote?: InputMaybe<Scalars['String']>;
  technicalSpecificationsNoteDe?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  unNumber1?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  unNumber2?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  unit?: InputMaybe<Scalars['String']>;
  unitOfMeasure?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  unitPointsCalculation?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  valuePointsCalculation?: InputMaybe<Scalars['String']>;
  vendorNo?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
  vk1?: InputMaybe<Scalars['String']>;
  vk2?: InputMaybe<Scalars['String']>;
  vk3?: InputMaybe<Scalars['String']>;
  vk4?: InputMaybe<Scalars['String']>;
  voltageFrom?: InputMaybe<Scalars['String']>;
  voltageTo?: InputMaybe<Scalars['String']>;
};

export type DataTechnicalOption = {
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataTechnicalSpecification = {
  id: Scalars['ID'];
  options?: InputMaybe<Array<InputMaybe<DataTechnicalOption>>>;
  selectedOption?: InputMaybe<Scalars['ID']>;
  selectedType: Scalars['ID'];
  sorting?: InputMaybe<Scalars['String']>;
};

export type DataUser = {
  crUser?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type DataUserGroup = {
  crUser?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  altName?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FileArray = {
  __typename?: 'FileArray';
  file?: Maybe<File>;
};

export type FileFields = {
  __typename?: 'FileFields';
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  importId?: Maybe<Scalars['ID']>;
};

export type Filter = {
  fieldName?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<InputMaybe<FilterOption>>>;
  selectedOption?: InputMaybe<FilterOption>;
};

export type FilterOption = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Folder = {
  __typename?: 'Folder';
  id?: Maybe<Scalars['ID']>;
  parent?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FolderArray = {
  __typename?: 'FolderArray';
  errorMessage?: Maybe<Scalars['String']>;
  folder?: Maybe<Folder>;
};

export type Import = {
  __typename?: 'Import';
  crUser?: Maybe<User>;
  cronSchedule?: Maybe<Scalars['String']>;
  enclosure?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imagesFileDirectory?: Maybe<Scalars['String']>;
  importSource?: Maybe<Scalars['String']>;
  separator?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  validImportFile?: Maybe<Scalars['Boolean']>;
};

export type ImportArray = {
  __typename?: 'ImportArray';
  file?: Maybe<InlineMedia>;
  import?: Maybe<ImportData>;
};

export type ImportData = {
  __typename?: 'ImportData';
  crUser?: Maybe<Scalars['ID']>;
  cronSchedule?: Maybe<Scalars['String']>;
  enclosure?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  imagesFileDirectory?: Maybe<Scalars['String']>;
  importSource?: Maybe<Scalars['String']>;
  separator?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  validImportFile?: Maybe<Scalars['Boolean']>;
};

export type InitInlineMedia = {
  __typename?: 'InitInlineMedia';
  field: Scalars['String'];
  inlineMedia: InlineMedia;
};

export type InlineAdditionalOptions = {
  __typename?: 'InlineAdditionalOptions';
  id?: Maybe<Scalars['ID']>;
  sorting?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type InlineContentElement = {
  __typename?: 'InlineContentElement';
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<MediaItem>>>;
  label?: Maybe<Scalars['String']>;
  labelDe?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  linkDe?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleDe?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  videoDe?: Maybe<Scalars['String']>;
};

export type InlineMedia = {
  __typename?: 'InlineMedia';
  allowedExtensions?: Maybe<Scalars['String']>;
  fieldType?: Maybe<Scalars['String']>;
  isMultiple?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<MediaItem>>>;
};

export type InlineOptions = {
  __typename?: 'InlineOptions';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  sorting?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  textDe?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type InlineTechnicalSpecification = {
  __typename?: 'InlineTechnicalSpecification';
  id?: Maybe<Scalars['ID']>;
  options?: Maybe<Array<Maybe<TechnicalOption>>>;
  selectedOption?: Maybe<Scalars['ID']>;
  selectedType?: Maybe<Scalars['ID']>;
  sorting?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['ID']>;
};

export type Language = {
  __typename?: 'Language';
  code?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type LanguageArray = {
  __typename?: 'LanguageArray';
  language?: Maybe<LanguageData>;
};

export type LanguageData = {
  __typename?: 'LanguageData';
  code?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type MappedFields = {
  __typename?: 'MappedFields';
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  importId?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  mappedField?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  unique?: Maybe<Scalars['Boolean']>;
};

export type MappedFieldsData = {
  field?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  importId?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  mappedField?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  unique?: InputMaybe<Scalars['Boolean']>;
};

export type MediaItem = {
  __typename?: 'MediaItem';
  altName?: Maybe<Scalars['String']>;
  altTitle?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAdditional: AdditionalArray;
  createApproval: ApprovalArray;
  createArticle: ArticleArray;
  createCategory: CategoryArray;
  createCompany: CompanyArray;
  createDangerousGood: DangerousGoodArray;
  createFile: Scalars['Boolean'];
  createFolder: FolderArray;
  createImport: ImportArray;
  createPlatform: PlatformArray;
  createProduct: ProductArray;
  createReference: ReferenceArray;
  createUser?: Maybe<UserArray>;
  createUserGroup: UserGroupArray;
  deleteAdditional: Additional;
  deleteApproval: Approval;
  deleteArticle?: Maybe<Article>;
  deleteCategory?: Maybe<Category>;
  deleteCompany: Company;
  deleteDangerousGood: DangerousGood;
  deleteFile?: Maybe<File>;
  deleteFolder?: Maybe<Folder>;
  deleteImport: Import;
  deletePlatform: Platform;
  deleteProduct?: Maybe<Product>;
  deleteUser?: Maybe<User>;
  deleteUserGroup: UserGroup;
  exportAdditionals: Scalars['String'];
  exportApprovals: Scalars['String'];
  exportArticles: Scalars['String'];
  exportCategories: Scalars['String'];
  exportCompanies: Scalars['String'];
  exportDangerousGoods: Scalars['String'];
  exportMediaFiles: Scalars['String'];
  exportModelData: Scalars['String'];
  exportPlatforms: Scalars['String'];
  exportProducts: Scalars['String'];
  forgotPassword?: Maybe<Scalars['Boolean']>;
  generatePdf: Scalars['Boolean'];
  importArticles: Scalars['Boolean'];
  importCategories: Scalars['Boolean'];
  importModelData: Scalars['Boolean'];
  inlineEditUpdate: Scalars['Boolean'];
  inlineMediaUpload: Array<Maybe<MediaItem>>;
  multiResetApproval: Scalars['Boolean'];
  multiResetArticle: Scalars['Boolean'];
  multiResetCategory: Scalars['Boolean'];
  multiResetCompany: Scalars['Boolean'];
  multiResetDangerousGood: Scalars['Boolean'];
  multiResetProduct: Scalars['Boolean'];
  multiUpdateApproval: ApprovalArray;
  multiUpdateArticle: ArticleArray;
  multiUpdateCategory: CategoryArray;
  multiUpdateCompany: CompanyArray;
  multiUpdateDangerousGood: DangerousGoodArray;
  multiUpdatePlatform: PlatformArray;
  multiUpdateProduct: ProductArray;
  refreshAccessToken: AuthUser;
  resetAdditional: Scalars['Boolean'];
  resetApproval: Scalars['Boolean'];
  resetArticle: Scalars['Boolean'];
  resetCategory: Scalars['Boolean'];
  resetCompany: Scalars['Boolean'];
  resetDangerousGood: Scalars['Boolean'];
  resetImport: Scalars['Boolean'];
  resetPassword?: Maybe<Scalars['Boolean']>;
  resetProduct: Scalars['Boolean'];
  resetProfilePassword?: Maybe<ResetRespos>;
  runImport?: Maybe<Scalars['Boolean']>;
  setDynamicTable: Scalars['Boolean'];
  signIn: AuthUser;
  signOut?: Maybe<Scalars['Boolean']>;
  updateAdditional: AdditionalArray;
  updateApproval: ApprovalArray;
  updateArticle: ArticleArray;
  updateCategory: CategoryArray;
  updateCompany: CompanyArray;
  updateDangerousGood: DangerousGoodArray;
  updateFile: FileArray;
  updateFolder: FolderArray;
  updateImport: ImportArray;
  updatePlatform: PlatformArray;
  updateProduct: ProductArray;
  updateSelectField: SelectFieldArray;
  updateUser?: Maybe<UserArray>;
  updateUserGroup: UserGroupArray;
  validateFileImport?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateAdditionalArgs = {
  data: DataAdditional;
  model: Scalars['String'];
  options?: InputMaybe<Array<InputMaybe<DataAdditionalOption>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateApprovalArgs = {
  data: DataApproval;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateArticleArgs = {
  data: DataArticle;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataCategory;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  parents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentsShop?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateCompanyArgs = {
  data: DataCompany;
  logo?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateDangerousGoodArgs = {
  data: DataDangerousGood;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateFileArgs = {
  file?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  parent?: InputMaybe<Scalars['ID']>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationCreateFolderArgs = {
  data: DataFolder;
  parent?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateImportArgs = {
  data: DataImport;
  file?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreatePlatformArgs = {
  data: DataPlatform;
  model: Scalars['String'];
  user: Scalars['ID'];
};


export type MutationCreateProductArgs = {
  beaFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  cadFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoriesShop?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataProduct;
  expFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  moaFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  proFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  schFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  sdbFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  sdoFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  tdbFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  technicalSpecifications?: InputMaybe<Array<InputMaybe<DataTechnicalSpecification>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
  vidFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  zeiFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
};


export type MutationCreateReferenceArgs = {
  dataModel?: InputMaybe<DataModel>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationCreateUserArgs = {
  data: DataUser;
  model: Scalars['String'];
  user: Scalars['ID'];
};


export type MutationCreateUserGroupArgs = {
  data: DataUserGroup;
  model: Scalars['String'];
  user: Scalars['ID'];
};


export type MutationDeleteAdditionalArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteApprovalArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteArticleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDangerousGoodArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteImportArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePlatformArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserGroupArgs = {
  id: Scalars['ID'];
};


export type MutationExportAdditionalsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportApprovalsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportArticlesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportCategoriesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportCompaniesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportDangerousGoodsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportMediaFilesArgs = {
  folder?: InputMaybe<Scalars['ID']>;
};


export type MutationExportModelDataArgs = {
  exportType?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportPlatformsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportProductsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGeneratePdfArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationImportArticlesArgs = {
  file?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  model: Scalars['String'];
};


export type MutationImportCategoriesArgs = {
  file?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  model: Scalars['String'];
};


export type MutationImportModelDataArgs = {
  file?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  model: Scalars['String'];
};


export type MutationInlineEditUpdateArgs = {
  data: Scalars['Json'];
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type MutationInlineMediaUploadArgs = {
  field: Scalars['String'];
  files?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  folder?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetApprovalArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetArticleArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetCategoryArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetCompanyArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetDangerousGoodArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetProductArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiUpdateApprovalArgs = {
  data: DataApproval;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdateArticleArgs = {
  data: DataArticle;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdateCategoryArgs = {
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataCategory;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  parents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentsShop?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdateCompanyArgs = {
  data: DataCompany;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  logo?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdateDangerousGoodArgs = {
  data: DataDangerousGood;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdatePlatformArgs = {
  data: DataPlatform;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiUpdateProductArgs = {
  beaFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  cadFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoriesShop?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataProduct;
  expFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  moaFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  proFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  schFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  sdbFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  sdoFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  tdbFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  technicalSpecifications?: InputMaybe<Array<InputMaybe<DataTechnicalSpecification>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  vidFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  zeiFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
};


export type MutationResetAdditionalArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetApprovalArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetArticleArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetDangerousGoodArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetImportArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetProfilePasswordArgs = {
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRunImportArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationSetDynamicTableArgs = {
  data: Scalars['Json'];
  model: Scalars['String'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateAdditionalArgs = {
  data: DataAdditional;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
  options?: InputMaybe<Array<InputMaybe<DataAdditionalOption>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateApprovalArgs = {
  data: DataApproval;
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateArticleArgs = {
  data?: InputMaybe<DataArticle>;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCategoryArgs = {
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataCategory;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  parents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentsShop?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCompanyArgs = {
  data: DataCompany;
  id?: InputMaybe<Scalars['ID']>;
  logo?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateDangerousGoodArgs = {
  data: DataDangerousGood;
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateFileArgs = {
  data: DataFile;
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateFolderArgs = {
  data: DataFolder;
  id?: InputMaybe<Scalars['ID']>;
  parent?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateImportArgs = {
  data: DataImport;
  file?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  mappedFields?: InputMaybe<Array<InputMaybe<MappedFieldsData>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdatePlatformArgs = {
  data: DataPlatform;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationUpdateProductArgs = {
  beaFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  cadFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoriesShop?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataProduct;
  expFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  moaFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  proFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  schFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  sdbFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  sdoFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  tdbFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  technicalSpecifications?: InputMaybe<Array<InputMaybe<DataTechnicalSpecification>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  vidFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  zeiFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
};


export type MutationUpdateSelectFieldArgs = {
  id?: InputMaybe<Scalars['ID']>;
  options?: InputMaybe<Array<InputMaybe<DataOption>>>;
};


export type MutationUpdateUserArgs = {
  data: DataUser;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationUpdateUserGroupArgs = {
  data: DataUserGroup;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationValidateFileImportArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

export type Pagination = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Platform = {
  __typename?: 'Platform';
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<User>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleTe?: Maybe<Scalars['String']>;
};

export type PlatformArray = {
  __typename?: 'PlatformArray';
  platform?: Maybe<PlatformData>;
};

export type PlatformData = {
  __typename?: 'PlatformData';
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  accessories?: Maybe<Array<Maybe<Product>>>;
  additionals?: Maybe<Array<Maybe<Product>>>;
  approvals?: Maybe<Array<Maybe<Approval>>>;
  approvalsNote?: Maybe<Scalars['String']>;
  approvalsNoteDe?: Maybe<Scalars['String']>;
  articles?: Maybe<Array<Maybe<Article>>>;
  assortmentItems?: Maybe<Scalars['String']>;
  catalogItems?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Category>>>;
  categoriesShop?: Maybe<Array<Maybe<Category>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  crUser?: Maybe<User>;
  creditArticleNumber?: Maybe<Scalars['String']>;
  customerItems?: Maybe<Scalars['String']>;
  dangerousGoods?: Maybe<Array<Maybe<DangerousGood>>>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  eanCode?: Maybe<Scalars['String']>;
  exportToErp?: Maybe<Scalars['Boolean']>;
  exportToWebshop?: Maybe<Scalars['Boolean']>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  genProductPosting?: Maybe<Array<Maybe<Scalars['ID']>>>;
  grossWeight?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  itemTrackingCode?: Maybe<Scalars['String']>;
  limitedQuantity?: Maybe<Scalars['String']>;
  lvsStockItems?: Maybe<Scalars['String']>;
  netWeight?: Maybe<Scalars['String']>;
  orderQuantity?: Maybe<Scalars['String']>;
  platforms?: Maybe<Array<Maybe<Platform>>>;
  price?: Maybe<Scalars['String']>;
  productType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  publishDate?: Maybe<Scalars['String']>;
  quantityPerPackaging?: Maybe<Scalars['String']>;
  quantityPerPallet?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  specialRegulation?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  supplierItemName?: Maybe<Scalars['String']>;
  supply?: Maybe<Array<Maybe<Scalars['ID']>>>;
  technicalSpecificationsNote?: Maybe<Scalars['String']>;
  technicalSpecificationsNoteDe?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleDe?: Maybe<Scalars['String']>;
  unNumber1?: Maybe<Array<Maybe<DangerousGood>>>;
  unNumber2?: Maybe<Array<Maybe<DangerousGood>>>;
  unit?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Array<Maybe<Scalars['ID']>>>;
  unitPointsCalculation?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  valuePointsCalculation?: Maybe<Scalars['String']>;
  vendorNo?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  vk1?: Maybe<Scalars['String']>;
  vk2?: Maybe<Scalars['String']>;
  vk3?: Maybe<Scalars['String']>;
  vk4?: Maybe<Scalars['String']>;
  voltageFrom?: Maybe<Scalars['String']>;
  voltageTo?: Maybe<Scalars['String']>;
};

export type ProductArray = {
  __typename?: 'ProductArray';
  beaFiles?: Maybe<InlineMedia>;
  cadFiles?: Maybe<InlineMedia>;
  contentElements?: Maybe<Array<Maybe<InlineContentElement>>>;
  erpFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  expFiles?: Maybe<InlineMedia>;
  files?: Maybe<InlineMedia>;
  headerImages?: Maybe<InlineMedia>;
  images?: Maybe<InlineMedia>;
  moaFiles?: Maybe<InlineMedia>;
  pimFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  proFiles?: Maybe<InlineMedia>;
  product?: Maybe<ProductData>;
  schFiles?: Maybe<InlineMedia>;
  sdbFiles?: Maybe<InlineMedia>;
  sdoFiles?: Maybe<InlineMedia>;
  shopFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  tdbFiles?: Maybe<InlineMedia>;
  technicalSpecifications?: Maybe<Array<Maybe<InlineTechnicalSpecification>>>;
  vidFiles?: Maybe<InlineMedia>;
  zeiFiles?: Maybe<InlineMedia>;
};

export type ProductData = {
  __typename?: 'ProductData';
  accessories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  additionals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  approvals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  approvalsNote?: Maybe<Scalars['String']>;
  approvalsNoteDe?: Maybe<Scalars['String']>;
  articles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assortmentItems?: Maybe<Scalars['String']>;
  catalogItems?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  categoriesShop?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>;
  crUser?: Maybe<Scalars['ID']>;
  creditArticleNumber?: Maybe<Scalars['String']>;
  customerItems?: Maybe<Scalars['String']>;
  dangerousGoods?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  eanCode?: Maybe<Scalars['String']>;
  exportToErp?: Maybe<Scalars['Boolean']>;
  exportToWebshop?: Maybe<Scalars['Boolean']>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  genProductPosting?: Maybe<Array<Maybe<Scalars['ID']>>>;
  grossWeight?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  itemTrackingCode?: Maybe<Scalars['String']>;
  limitedQuantity?: Maybe<Scalars['String']>;
  lvsStockItems?: Maybe<Scalars['String']>;
  netWeight?: Maybe<Scalars['String']>;
  orderQuantity?: Maybe<Scalars['String']>;
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  price?: Maybe<Scalars['String']>;
  productType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  publishDate?: Maybe<Scalars['String']>;
  quantityPerPackaging?: Maybe<Scalars['String']>;
  quantityPerPallet?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  specialRegulation?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  supplierItemName?: Maybe<Scalars['String']>;
  supply?: Maybe<Array<Maybe<Scalars['ID']>>>;
  technicalSpecificationsNote?: Maybe<Scalars['String']>;
  technicalSpecificationsNoteDe?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  unNumber1?: Maybe<Array<Maybe<Scalars['ID']>>>;
  unNumber2?: Maybe<Array<Maybe<Scalars['ID']>>>;
  unit?: Maybe<Scalars['String']>;
  unitOfMeasure?: Maybe<Array<Maybe<Scalars['ID']>>>;
  unitPointsCalculation?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  valuePointsCalculation?: Maybe<Scalars['String']>;
  vendorNo?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  vk1?: Maybe<Scalars['String']>;
  vk2?: Maybe<Scalars['String']>;
  vk3?: Maybe<Scalars['String']>;
  vk4?: Maybe<Scalars['String']>;
  voltageFrom?: Maybe<Scalars['String']>;
  voltageTo?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  additional: AdditionalArray;
  approval: ApprovalArray;
  approvalMultiEdit: Array<Maybe<ApprovalArray>>;
  article: ArticleArray;
  articleMultiEdit: Array<Maybe<ArticleArray>>;
  categoriesThree?: Maybe<Scalars['Json']>;
  category: CategoryArray;
  categoryMultiEdit: Array<Maybe<CategoryArray>>;
  company: CompanyArray;
  companyMultiEdit: Array<Maybe<CompanyArray>>;
  dangerousGood: DangerousGoodArray;
  dangerousGoodMultiEdit: Array<Maybe<DangerousGoodArray>>;
  entities: Array<ConfHeader>;
  fileFields: Array<FileFields>;
  getDynamicTable?: Maybe<Scalars['Json']>;
  getFile?: Maybe<FileArray>;
  getFolder?: Maybe<FolderArray>;
  getInlineEditTable?: Maybe<Scalars['Json']>;
  getMediacenter?: Maybe<Scalars['Json']>;
  getSelectField?: Maybe<SelectFieldArray>;
  getSelectFields?: Maybe<Array<Maybe<SelectField>>>;
  getSpecificSelect?: Maybe<Array<Maybe<SelectOption>>>;
  getTableSettings?: Maybe<Scalars['Json']>;
  getTechnicalSpecificationSelect?: Maybe<Array<Maybe<Additional>>>;
  getUnitSelect?: Maybe<Array<Maybe<UnitOption>>>;
  import: ImportArray;
  inlineMedia?: Maybe<Array<InitInlineMedia>>;
  mappedFields: Array<MappedFields>;
  platform: PlatformArray;
  platformMultiEdit: Array<Maybe<PlatformArray>>;
  product: ProductArray;
  productMultiEdit: Array<Maybe<ProductArray>>;
  selectAccessories: Array<Product>;
  selectAdditionals: Array<Approval>;
  selectApprovals: Array<Approval>;
  selectCompanies: Array<Company>;
  selectCountries: Array<Country>;
  selectDangerousGoods: Array<DangerousGood>;
  selectImport: Array<Import>;
  selectPlatforms: Array<Platform>;
  selectProduct: Array<Product>;
  selectStates: Array<State>;
  selectType?: Maybe<Array<SimpleSelectOption>>;
  translateApproval: ApprovalArray;
  translateArticle: ArticleArray;
  translateCategory: CategoryArray;
  translateCompany: CompanyArray;
  translateDangerousGood: DangerousGoodArray;
  translatePlatform: PlatformArray;
  translateProduct: ProductArray;
  user: UserArray;
  userGroup: UserGroupArray;
};


export type QueryAdditionalArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryApprovalArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryApprovalMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QueryArticleArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryArticleMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  mediacenter?: InputMaybe<Scalars['Boolean']>;
  model: Scalars['String'];
};


export type QueryCategoriesThreeArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model?: InputMaybe<Scalars['String']>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryCategoryMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  mediacenter?: InputMaybe<Scalars['Boolean']>;
  model: Scalars['String'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryCompanyMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QueryDangerousGoodArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryDangerousGoodMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QueryFileFieldsArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryGetDynamicTableArgs = {
  filter?: InputMaybe<Scalars['Json']>;
  model: Scalars['String'];
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Sorting>;
};


export type QueryGetFileArgs = {
  id: Scalars['ID'];
};


export type QueryGetFolderArgs = {
  id: Scalars['ID'];
};


export type QueryGetInlineEditTableArgs = {
  field: Scalars['String'];
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryGetMediacenterArgs = {
  dataModel?: InputMaybe<DataModel>;
  id?: InputMaybe<Scalars['ID']>;
  pagination: Pagination;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetSelectFieldArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetSpecificSelectArgs = {
  model: Scalars['String'];
};


export type QueryGetTableSettingsArgs = {
  model: Scalars['String'];
};


export type QueryGetTechnicalSpecificationSelectArgs = {
  model: Scalars['String'];
  trashed?: InputMaybe<Trashed>;
};


export type QueryGetUnitSelectArgs = {
  model: Scalars['String'];
};


export type QueryImportArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryInlineMediaArgs = {
  fields?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryMappedFieldsArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryPlatformArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryPlatformMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryProductMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QuerySelectAccessoriesArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySelectAdditionalsArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectApprovalsArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectCompaniesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectCountriesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectDangerousGoodsArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectImportArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectPlatformsArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectStatesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QueryTranslateApprovalArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateArticleArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateCategoryArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateCompanyArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateDangerousGoodArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslatePlatformArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateProductArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model?: InputMaybe<Scalars['String']>;
};


export type QueryUserGroupArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};

export type ReferenceArray = {
  __typename?: 'ReferenceArray';
  id?: Maybe<Scalars['ID']>;
  model?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ResetRespos = {
  __typename?: 'ResetRespos';
  error?: Maybe<Scalars['Json']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SelectField = {
  __typename?: 'SelectField';
  field: Scalars['String'];
  id: Scalars['ID'];
  model: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type SelectFieldArray = {
  __typename?: 'SelectFieldArray';
  options?: Maybe<Array<Maybe<InlineOptions>>>;
  selectField?: Maybe<SelectField>;
};

export type SelectOption = {
  __typename?: 'SelectOption';
  field: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  titleDe?: Maybe<Scalars['String']>;
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

export type SimpleSelectOption = {
  __typename?: 'SimpleSelectOption';
  label: Scalars['String'];
  value: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Sorting = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type State = {
  __typename?: 'State';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type TechnicalOption = {
  __typename?: 'TechnicalOption';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UnitOption = {
  __typename?: 'UnitOption';
  field: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  crUser?: Maybe<User>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isOnline?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type UserArray = {
  __typename?: 'UserArray';
  user?: Maybe<UserData>;
};

export type UserData = {
  __typename?: 'UserData';
  crUser?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isOnline?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  cr_user?: Maybe<User>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type UserGroupArray = {
  __typename?: 'UserGroupArray';
  images?: Maybe<InlineMedia>;
  userGroup?: Maybe<UserGroupData>;
};

export type UserGroupData = {
  __typename?: 'UserGroupData';
  crUser?: Maybe<Scalars['ID']>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type ArticleQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  model: Types.Scalars['String'];
}>;


export type ArticleQuery = { __typename?: 'Query', article: { __typename?: 'ArticleArray', article?: { __typename?: 'ArticleData', articleNo?: string | null, title?: string | null, description?: string | null, platforms?: Array<string | null> | null, hidden?: boolean | null, product?: string | null, sorting?: string | null } | null, images?: { __typename?: 'InlineMedia', isMultiple?: boolean | null, fieldType?: string | null, allowedExtensions?: string | null, items?: Array<{ __typename?: 'MediaItem', id: string, fileName?: string | null, filePath?: string | null, thumbnail?: string | null, title?: string | null, altTitle?: string | null, description?: string | null, author?: string | null } | null> | null } | null, files?: { __typename?: 'InlineMedia', isMultiple?: boolean | null, fieldType?: string | null, allowedExtensions?: string | null, items?: Array<{ __typename?: 'MediaItem', id: string, fileName?: string | null, filePath?: string | null, thumbnail?: string | null, title?: string | null, altTitle?: string | null, description?: string | null, author?: string | null } | null> | null } | null }, getSpecificSelect?: Array<{ __typename?: 'SelectOption', id: string, title: string, field: string } | null> | null, selectPlatforms: Array<{ __typename?: 'Platform', id: string, title?: string | null }>, selectProduct: Array<{ __typename?: 'Product', id: string, title: string }>, user: { __typename?: 'UserArray', user?: { __typename?: 'UserData', id: string } | null } };

export type ArticleMultiEditQueryVariables = Types.Exact<{
  ids: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  model: Types.Scalars['String'];
  fields?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type ArticleMultiEditQuery = { __typename?: 'Query', articleMultiEdit: Array<{ __typename?: 'ArticleArray', article?: { __typename?: 'ArticleData', title?: string | null } | null } | null>, inlineMedia?: Array<{ __typename?: 'InitInlineMedia', field: string, inlineMedia: { __typename?: 'InlineMedia', fieldType?: string | null, allowedExtensions?: string | null, isMultiple?: boolean | null } }> | null, getSpecificSelect?: Array<{ __typename?: 'SelectOption', id: string, title: string, field: string } | null> | null, selectPlatforms: Array<{ __typename?: 'Platform', id: string, title?: string | null }>, selectProduct: Array<{ __typename?: 'Product', id: string, title: string }> };

export type NewArticleQueryVariables = Types.Exact<{
  fields?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  model: Types.Scalars['String'];
}>;


export type NewArticleQuery = { __typename?: 'Query', inlineMedia?: Array<{ __typename?: 'InitInlineMedia', field: string, inlineMedia: { __typename?: 'InlineMedia', fieldType?: string | null, allowedExtensions?: string | null, isMultiple?: boolean | null } }> | null, getSpecificSelect?: Array<{ __typename?: 'SelectOption', id: string, title: string, field: string } | null> | null, selectPlatforms: Array<{ __typename?: 'Platform', id: string, title?: string | null }>, selectProduct: Array<{ __typename?: 'Product', id: string, title: string }>, user: { __typename?: 'UserArray', user?: { __typename?: 'UserData', id: string } | null } };

export type TranslateArticleQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  model: Types.Scalars['String'];
  language: Types.Scalars['String'];
}>;


export type TranslateArticleQuery = { __typename?: 'Query', translateArticle: { __typename?: 'ArticleArray', article?: { __typename?: 'ArticleData', title?: string | null, titleDe?: string | null, description?: string | null, descriptionDe?: string | null } | null } };

export type ArticlesListQueryVariables = Types.Exact<{
  model: Types.Scalars['String'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  pagination: Types.Pagination;
  sorting?: Types.InputMaybe<Types.Sorting>;
}>;


export type ArticlesListQuery = { __typename?: 'Query', getDynamicTable?: any | null };

export type UpdateArticleMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  id: Types.Scalars['ID'];
  data: Types.DataArticle;
  images?: Types.InputMaybe<Array<Types.InputMaybe<Types.DataMedia>> | Types.InputMaybe<Types.DataMedia>>;
  files?: Types.InputMaybe<Array<Types.InputMaybe<Types.DataMedia>> | Types.InputMaybe<Types.DataMedia>>;
  removedMedia?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type UpdateArticleMutation = { __typename?: 'Mutation', updateArticle: { __typename?: 'ArticleArray', article?: { __typename?: 'ArticleData', id?: string | null } | null } };

export type MultiUpdateArticleMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  ids: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  data: Types.DataArticle;
  images?: Types.InputMaybe<Array<Types.InputMaybe<Types.DataMedia>> | Types.InputMaybe<Types.DataMedia>>;
  files?: Types.InputMaybe<Array<Types.InputMaybe<Types.DataMedia>> | Types.InputMaybe<Types.DataMedia>>;
  removedMedia?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type MultiUpdateArticleMutation = { __typename?: 'Mutation', multiUpdateArticle: { __typename?: 'ArticleArray', article?: { __typename?: 'ArticleData', id?: string | null } | null } };

export type CreateArticleMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  user: Types.Scalars['ID'];
  data: Types.DataArticle;
  images?: Types.InputMaybe<Array<Types.InputMaybe<Types.DataMedia>> | Types.InputMaybe<Types.DataMedia>>;
  files?: Types.InputMaybe<Array<Types.InputMaybe<Types.DataMedia>> | Types.InputMaybe<Types.DataMedia>>;
  removedMedia?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type CreateArticleMutation = { __typename?: 'Mutation', createArticle: { __typename?: 'ArticleArray', article?: { __typename?: 'ArticleData', id?: string | null } | null } };

export type InlineMediaUploadArticleMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  model: Types.Scalars['String'];
  field: Types.Scalars['String'];
  images?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Upload']>> | Types.InputMaybe<Types.Scalars['Upload']>>;
}>;


export type InlineMediaUploadArticleMutation = { __typename?: 'Mutation', inlineMediaUpload: Array<{ __typename?: 'MediaItem', id: string, fileName?: string | null, filePath?: string | null, thumbnail?: string | null, title?: string | null, altTitle?: string | null, description?: string | null, author?: string | null } | null> };

export type DeleteArticleMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteArticleMutation = { __typename?: 'Mutation', deleteArticle?: { __typename?: 'Article', id?: string | null } | null };

export type ResetArticleMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  model: Types.Scalars['String'];
}>;


export type ResetArticleMutation = { __typename?: 'Mutation', resetArticle: boolean };

export type MultiResetArticleMutationVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
  model: Types.Scalars['String'];
}>;


export type MultiResetArticleMutation = { __typename?: 'Mutation', multiResetArticle: boolean };

export type ExportCsvArticleMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  exportType?: Types.InputMaybe<Types.Scalars['String']>;
  ids?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type ExportCsvArticleMutation = { __typename?: 'Mutation', exportModelData: string };

export type ImportCsvArticleMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  file?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Upload']>> | Types.InputMaybe<Types.Scalars['Upload']>>;
}>;


export type ImportCsvArticleMutation = { __typename?: 'Mutation', importModelData: boolean };

export type GeneratePdfArticlesMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  ids?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type GeneratePdfArticlesMutation = { __typename?: 'Mutation', generatePdf: boolean };


export const ArticleDocument = gql`
    query Article($id: ID!, $model: String!) {
  article(id: $id, model: $model) {
    article {
      articleNo
      title
      description
      platforms
      hidden
      product
      sorting
    }
    images {
      items {
        id
        fileName
        filePath
        thumbnail
        title
        altTitle
        description
        author
      }
      isMultiple
      fieldType
      allowedExtensions
    }
    files {
      items {
        id
        fileName
        filePath
        thumbnail
        title
        altTitle
        description
        author
      }
      isMultiple
      fieldType
      allowedExtensions
    }
  }
  getSpecificSelect(model: $model) {
    id
    title
    field
  }
  selectPlatforms {
    id
    title
  }
  selectProduct {
    id
    title
  }
  user {
    user {
      id
    }
  }
}
    `;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useArticleQuery(baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
      }
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const ArticleMultiEditDocument = gql`
    query ArticleMultiEdit($ids: [ID]!, $model: String!, $fields: [String!]) {
  articleMultiEdit(ids: $ids, model: $model) {
    article {
      title
    }
  }
  inlineMedia(fields: $fields) {
    field
    inlineMedia {
      fieldType
      allowedExtensions
      isMultiple
    }
  }
  getSpecificSelect(model: $model) {
    id
    title
    field
  }
  selectPlatforms {
    id
    title
  }
  selectProduct {
    id
    title
  }
}
    `;

/**
 * __useArticleMultiEditQuery__
 *
 * To run a query within a React component, call `useArticleMultiEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleMultiEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleMultiEditQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      model: // value for 'model'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useArticleMultiEditQuery(baseOptions: Apollo.QueryHookOptions<ArticleMultiEditQuery, ArticleMultiEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleMultiEditQuery, ArticleMultiEditQueryVariables>(ArticleMultiEditDocument, options);
      }
export function useArticleMultiEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleMultiEditQuery, ArticleMultiEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleMultiEditQuery, ArticleMultiEditQueryVariables>(ArticleMultiEditDocument, options);
        }
export type ArticleMultiEditQueryHookResult = ReturnType<typeof useArticleMultiEditQuery>;
export type ArticleMultiEditLazyQueryHookResult = ReturnType<typeof useArticleMultiEditLazyQuery>;
export type ArticleMultiEditQueryResult = Apollo.QueryResult<ArticleMultiEditQuery, ArticleMultiEditQueryVariables>;
export const NewArticleDocument = gql`
    query newArticle($fields: [String!], $model: String!) {
  inlineMedia(fields: $fields) {
    field
    inlineMedia {
      fieldType
      allowedExtensions
      isMultiple
    }
  }
  getSpecificSelect(model: $model) {
    id
    title
    field
  }
  selectPlatforms {
    id
    title
  }
  selectProduct {
    id
    title
  }
  user {
    user {
      id
    }
  }
}
    `;

/**
 * __useNewArticleQuery__
 *
 * To run a query within a React component, call `useNewArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewArticleQuery({
 *   variables: {
 *      fields: // value for 'fields'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useNewArticleQuery(baseOptions: Apollo.QueryHookOptions<NewArticleQuery, NewArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewArticleQuery, NewArticleQueryVariables>(NewArticleDocument, options);
      }
export function useNewArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewArticleQuery, NewArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewArticleQuery, NewArticleQueryVariables>(NewArticleDocument, options);
        }
export type NewArticleQueryHookResult = ReturnType<typeof useNewArticleQuery>;
export type NewArticleLazyQueryHookResult = ReturnType<typeof useNewArticleLazyQuery>;
export type NewArticleQueryResult = Apollo.QueryResult<NewArticleQuery, NewArticleQueryVariables>;
export const TranslateArticleDocument = gql`
    query TranslateArticle($id: ID!, $model: String!, $language: String!) {
  translateArticle(id: $id, model: $model, language: $language) {
    article {
      title
      titleDe
      description
      descriptionDe
    }
  }
}
    `;

/**
 * __useTranslateArticleQuery__
 *
 * To run a query within a React component, call `useTranslateArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslateArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslateArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *      model: // value for 'model'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useTranslateArticleQuery(baseOptions: Apollo.QueryHookOptions<TranslateArticleQuery, TranslateArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TranslateArticleQuery, TranslateArticleQueryVariables>(TranslateArticleDocument, options);
      }
export function useTranslateArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TranslateArticleQuery, TranslateArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TranslateArticleQuery, TranslateArticleQueryVariables>(TranslateArticleDocument, options);
        }
export type TranslateArticleQueryHookResult = ReturnType<typeof useTranslateArticleQuery>;
export type TranslateArticleLazyQueryHookResult = ReturnType<typeof useTranslateArticleLazyQuery>;
export type TranslateArticleQueryResult = Apollo.QueryResult<TranslateArticleQuery, TranslateArticleQueryVariables>;
export const ArticlesListDocument = gql`
    query ArticlesList($model: String!, $search: String, $pagination: Pagination!, $sorting: Sorting) {
  getDynamicTable(
    model: $model
    search: $search
    pagination: $pagination
    sorting: $sorting
  )
}
    `;

/**
 * __useArticlesListQuery__
 *
 * To run a query within a React component, call `useArticlesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesListQuery({
 *   variables: {
 *      model: // value for 'model'
 *      search: // value for 'search'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useArticlesListQuery(baseOptions: Apollo.QueryHookOptions<ArticlesListQuery, ArticlesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticlesListQuery, ArticlesListQueryVariables>(ArticlesListDocument, options);
      }
export function useArticlesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlesListQuery, ArticlesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticlesListQuery, ArticlesListQueryVariables>(ArticlesListDocument, options);
        }
export type ArticlesListQueryHookResult = ReturnType<typeof useArticlesListQuery>;
export type ArticlesListLazyQueryHookResult = ReturnType<typeof useArticlesListLazyQuery>;
export type ArticlesListQueryResult = Apollo.QueryResult<ArticlesListQuery, ArticlesListQueryVariables>;
export const UpdateArticleDocument = gql`
    mutation UpdateArticle($model: String!, $id: ID!, $data: DataArticle!, $images: [DataMedia], $files: [DataMedia], $removedMedia: [ID]) {
  updateArticle(
    model: $model
    id: $id
    data: $data
    images: $images
    files: $files
    removedMedia: $removedMedia
  ) {
    article {
      id
    }
  }
}
    `;
export type UpdateArticleMutationFn = Apollo.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      model: // value for 'model'
 *      id: // value for 'id'
 *      data: // value for 'data'
 *      images: // value for 'images'
 *      files: // value for 'files'
 *      removedMedia: // value for 'removedMedia'
 *   },
 * });
 */
export function useUpdateArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, options);
      }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<UpdateArticleMutation, UpdateArticleMutationVariables>;
export const MultiUpdateArticleDocument = gql`
    mutation MultiUpdateArticle($model: String!, $ids: [ID]!, $data: DataArticle!, $images: [DataMedia], $files: [DataMedia], $removedMedia: [ID]) {
  multiUpdateArticle(
    model: $model
    ids: $ids
    data: $data
    images: $images
    files: $files
    removedMedia: $removedMedia
  ) {
    article {
      id
    }
  }
}
    `;
export type MultiUpdateArticleMutationFn = Apollo.MutationFunction<MultiUpdateArticleMutation, MultiUpdateArticleMutationVariables>;

/**
 * __useMultiUpdateArticleMutation__
 *
 * To run a mutation, you first call `useMultiUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultiUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multiUpdateArticleMutation, { data, loading, error }] = useMultiUpdateArticleMutation({
 *   variables: {
 *      model: // value for 'model'
 *      ids: // value for 'ids'
 *      data: // value for 'data'
 *      images: // value for 'images'
 *      files: // value for 'files'
 *      removedMedia: // value for 'removedMedia'
 *   },
 * });
 */
export function useMultiUpdateArticleMutation(baseOptions?: Apollo.MutationHookOptions<MultiUpdateArticleMutation, MultiUpdateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MultiUpdateArticleMutation, MultiUpdateArticleMutationVariables>(MultiUpdateArticleDocument, options);
      }
export type MultiUpdateArticleMutationHookResult = ReturnType<typeof useMultiUpdateArticleMutation>;
export type MultiUpdateArticleMutationResult = Apollo.MutationResult<MultiUpdateArticleMutation>;
export type MultiUpdateArticleMutationOptions = Apollo.BaseMutationOptions<MultiUpdateArticleMutation, MultiUpdateArticleMutationVariables>;
export const CreateArticleDocument = gql`
    mutation CreateArticle($model: String!, $user: ID!, $data: DataArticle!, $images: [DataMedia], $files: [DataMedia], $removedMedia: [ID]) {
  createArticle(
    model: $model
    user: $user
    data: $data
    images: $images
    files: $files
    removedMedia: $removedMedia
  ) {
    article {
      id
    }
  }
}
    `;
export type CreateArticleMutationFn = Apollo.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      model: // value for 'model'
 *      user: // value for 'user'
 *      data: // value for 'data'
 *      images: // value for 'images'
 *      files: // value for 'files'
 *      removedMedia: // value for 'removedMedia'
 *   },
 * });
 */
export function useCreateArticleMutation(baseOptions?: Apollo.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(CreateArticleDocument, options);
      }
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<CreateArticleMutation, CreateArticleMutationVariables>;
export const InlineMediaUploadArticleDocument = gql`
    mutation InlineMediaUploadArticle($id: ID, $model: String!, $field: String!, $images: [Upload]) {
  inlineMediaUpload(id: $id, model: $model, field: $field, images: $images) {
    id
    fileName
    filePath
    thumbnail
    title
    altTitle
    description
    author
  }
}
    `;
export type InlineMediaUploadArticleMutationFn = Apollo.MutationFunction<InlineMediaUploadArticleMutation, InlineMediaUploadArticleMutationVariables>;

/**
 * __useInlineMediaUploadArticleMutation__
 *
 * To run a mutation, you first call `useInlineMediaUploadArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInlineMediaUploadArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inlineMediaUploadArticleMutation, { data, loading, error }] = useInlineMediaUploadArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      model: // value for 'model'
 *      field: // value for 'field'
 *      images: // value for 'images'
 *   },
 * });
 */
export function useInlineMediaUploadArticleMutation(baseOptions?: Apollo.MutationHookOptions<InlineMediaUploadArticleMutation, InlineMediaUploadArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InlineMediaUploadArticleMutation, InlineMediaUploadArticleMutationVariables>(InlineMediaUploadArticleDocument, options);
      }
export type InlineMediaUploadArticleMutationHookResult = ReturnType<typeof useInlineMediaUploadArticleMutation>;
export type InlineMediaUploadArticleMutationResult = Apollo.MutationResult<InlineMediaUploadArticleMutation>;
export type InlineMediaUploadArticleMutationOptions = Apollo.BaseMutationOptions<InlineMediaUploadArticleMutation, InlineMediaUploadArticleMutationVariables>;
export const DeleteArticleDocument = gql`
    mutation DeleteArticle($id: ID!) {
  deleteArticle(id: $id) {
    id
  }
}
    `;
export type DeleteArticleMutationFn = Apollo.MutationFunction<DeleteArticleMutation, DeleteArticleMutationVariables>;

/**
 * __useDeleteArticleMutation__
 *
 * To run a mutation, you first call `useDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleMutation, { data, loading, error }] = useDeleteArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArticleMutation, DeleteArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArticleMutation, DeleteArticleMutationVariables>(DeleteArticleDocument, options);
      }
export type DeleteArticleMutationHookResult = ReturnType<typeof useDeleteArticleMutation>;
export type DeleteArticleMutationResult = Apollo.MutationResult<DeleteArticleMutation>;
export type DeleteArticleMutationOptions = Apollo.BaseMutationOptions<DeleteArticleMutation, DeleteArticleMutationVariables>;
export const ResetArticleDocument = gql`
    mutation ResetArticle($id: ID, $model: String!) {
  resetArticle(id: $id, model: $model)
}
    `;
export type ResetArticleMutationFn = Apollo.MutationFunction<ResetArticleMutation, ResetArticleMutationVariables>;

/**
 * __useResetArticleMutation__
 *
 * To run a mutation, you first call `useResetArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetArticleMutation, { data, loading, error }] = useResetArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useResetArticleMutation(baseOptions?: Apollo.MutationHookOptions<ResetArticleMutation, ResetArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetArticleMutation, ResetArticleMutationVariables>(ResetArticleDocument, options);
      }
export type ResetArticleMutationHookResult = ReturnType<typeof useResetArticleMutation>;
export type ResetArticleMutationResult = Apollo.MutationResult<ResetArticleMutation>;
export type ResetArticleMutationOptions = Apollo.BaseMutationOptions<ResetArticleMutation, ResetArticleMutationVariables>;
export const MultiResetArticleDocument = gql`
    mutation MultiResetArticle($ids: [ID], $model: String!) {
  multiResetArticle(ids: $ids, model: $model)
}
    `;
export type MultiResetArticleMutationFn = Apollo.MutationFunction<MultiResetArticleMutation, MultiResetArticleMutationVariables>;

/**
 * __useMultiResetArticleMutation__
 *
 * To run a mutation, you first call `useMultiResetArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultiResetArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multiResetArticleMutation, { data, loading, error }] = useMultiResetArticleMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useMultiResetArticleMutation(baseOptions?: Apollo.MutationHookOptions<MultiResetArticleMutation, MultiResetArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MultiResetArticleMutation, MultiResetArticleMutationVariables>(MultiResetArticleDocument, options);
      }
export type MultiResetArticleMutationHookResult = ReturnType<typeof useMultiResetArticleMutation>;
export type MultiResetArticleMutationResult = Apollo.MutationResult<MultiResetArticleMutation>;
export type MultiResetArticleMutationOptions = Apollo.BaseMutationOptions<MultiResetArticleMutation, MultiResetArticleMutationVariables>;
export const ExportCsvArticleDocument = gql`
    mutation ExportCsvArticle($model: String!, $exportType: String, $ids: [ID]) {
  exportModelData(model: $model, exportType: $exportType, ids: $ids)
}
    `;
export type ExportCsvArticleMutationFn = Apollo.MutationFunction<ExportCsvArticleMutation, ExportCsvArticleMutationVariables>;

/**
 * __useExportCsvArticleMutation__
 *
 * To run a mutation, you first call `useExportCsvArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportCsvArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportCsvArticleMutation, { data, loading, error }] = useExportCsvArticleMutation({
 *   variables: {
 *      model: // value for 'model'
 *      exportType: // value for 'exportType'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useExportCsvArticleMutation(baseOptions?: Apollo.MutationHookOptions<ExportCsvArticleMutation, ExportCsvArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportCsvArticleMutation, ExportCsvArticleMutationVariables>(ExportCsvArticleDocument, options);
      }
export type ExportCsvArticleMutationHookResult = ReturnType<typeof useExportCsvArticleMutation>;
export type ExportCsvArticleMutationResult = Apollo.MutationResult<ExportCsvArticleMutation>;
export type ExportCsvArticleMutationOptions = Apollo.BaseMutationOptions<ExportCsvArticleMutation, ExportCsvArticleMutationVariables>;
export const ImportCsvArticleDocument = gql`
    mutation ImportCsvArticle($model: String!, $file: [Upload]) {
  importModelData(model: $model, file: $file)
}
    `;
export type ImportCsvArticleMutationFn = Apollo.MutationFunction<ImportCsvArticleMutation, ImportCsvArticleMutationVariables>;

/**
 * __useImportCsvArticleMutation__
 *
 * To run a mutation, you first call `useImportCsvArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCsvArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCsvArticleMutation, { data, loading, error }] = useImportCsvArticleMutation({
 *   variables: {
 *      model: // value for 'model'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportCsvArticleMutation(baseOptions?: Apollo.MutationHookOptions<ImportCsvArticleMutation, ImportCsvArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportCsvArticleMutation, ImportCsvArticleMutationVariables>(ImportCsvArticleDocument, options);
      }
export type ImportCsvArticleMutationHookResult = ReturnType<typeof useImportCsvArticleMutation>;
export type ImportCsvArticleMutationResult = Apollo.MutationResult<ImportCsvArticleMutation>;
export type ImportCsvArticleMutationOptions = Apollo.BaseMutationOptions<ImportCsvArticleMutation, ImportCsvArticleMutationVariables>;
export const GeneratePdfArticlesDocument = gql`
    mutation GeneratePdfArticles($model: String!, $ids: [ID]) {
  generatePdf(model: $model, ids: $ids)
}
    `;
export type GeneratePdfArticlesMutationFn = Apollo.MutationFunction<GeneratePdfArticlesMutation, GeneratePdfArticlesMutationVariables>;

/**
 * __useGeneratePdfArticlesMutation__
 *
 * To run a mutation, you first call `useGeneratePdfArticlesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePdfArticlesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePdfArticlesMutation, { data, loading, error }] = useGeneratePdfArticlesMutation({
 *   variables: {
 *      model: // value for 'model'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGeneratePdfArticlesMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePdfArticlesMutation, GeneratePdfArticlesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePdfArticlesMutation, GeneratePdfArticlesMutationVariables>(GeneratePdfArticlesDocument, options);
      }
export type GeneratePdfArticlesMutationHookResult = ReturnType<typeof useGeneratePdfArticlesMutation>;
export type GeneratePdfArticlesMutationResult = Apollo.MutationResult<GeneratePdfArticlesMutation>;
export type GeneratePdfArticlesMutationOptions = Apollo.BaseMutationOptions<GeneratePdfArticlesMutation, GeneratePdfArticlesMutationVariables>;