import * as React from "react";
import styled, {withTheme} from "styled-components/macro";
import {useTranslation} from "react-i18next";

import {
    Grid,
    AppBar as MuiAppBar,
    IconButton as MuiIconButton,
    Toolbar, ListItemButton, Typography,
} from "@mui/material";

import {Menu as MenuIcon} from "@mui/icons-material";


import NavbarUserDropdown from "./NavbarUserDropdown";
import {NavLink} from "react-router-dom";
import {ReactComponent as Logo} from "../../vendor/logo-new.svg";
import {useUserQuery} from "../../graphql/auth/auth.graphql-gen";


const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Brand = styled(ListItemButton)<{
    component?: React.ReactNode;
    to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 152px;
  height: 33px;
`;


type NavbarProps = {
    theme: {};
    onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};


const Navbar: React.FC<NavbarProps> = ({onDrawerToggle}) => {
    const {t} = useTranslation();
    const {data} = useUserQuery({
        variables: {model: 'User'}
    });
    return (
        <React.Fragment>
            <AppBar position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container alignItems="center">
                        <Brand component={NavLink} to="/">
                            <BrandIcon/>
                        </Brand>


                        <Grid item xs/>
                        <Typography variant="subtitle1" className="welcome-user">
                            {t("Willkommen")} <strong>{data?.user.user?.firstName} {data?.user.user?.lastName}</strong>{" "}
                        </Typography>
                        <Grid item>
                            <NavbarUserDropdown/>
                        </Grid>
                        <Grid item sx={{display: {xs: "block", md: "none"}}}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={onDrawerToggle}
                                size="large"
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default withTheme(Navbar);
