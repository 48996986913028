import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField, FormGroup, FormControlLabel, Checkbox, Divider as MuiDivider
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Divider = styled(MuiDivider)(spacing);


const GeneralInfoDanger: React.FC<FieldsGroupType> = (props) => {
    const {values, touched, errors, onChange, state, multiEdit} = props;

    // State
    const [valueHidden, setHidden] = useState<boolean>(values.hidden?.length == 0 || !values.hidden ? false : values.hidden);


    //------------------------------- Public methods -------------------------------------


    //update checkbox
    const handleHiddenChange = (event: any) => {
        setHidden(event.target.checked);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Allgemeine Informationen
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label={multiEdit ? 'Beschreibung' : 'Beschreibung *'}
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"/>
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="code"
                        label="Code"
                        value={values.code ? values.code : ''}
                        error={Boolean(touched.code && errors.code)}
                        fullWidth
                        helperText={touched.code && errors.code}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearCode"
                                    checked={state.clearCode ? state.clearCode : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="sorting"
                        label="Sortierung"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        value={values.sorting ? values.sorting : ''}
                        error={Boolean(touched.sorting && errors.sorting)}
                        fullWidth
                        helperText={touched.sorting && errors.sorting}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSorting"
                                    checked={state.clearSorting ? state.clearSorting : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="hidden"
                                checked={valueHidden}
                                onChange={(e) => {
                                    handleHiddenChange(e);
                                    onChange(e)
                                }}
                            />
                        } label="Versteckt"/>
                    </FormGroup>
                </Grid>

                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Beschreibung 2"
                        name="description"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.description ? values.description : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearDescription"
                                    checked={state.clearDescription ? state.clearDescription : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>

                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Technical Description"
                        name="technicalDescription"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.technicalDescription ? values.technicalDescription : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearTechnicalDescription"
                                    checked={state.clearTechnicalDescription ? state.clearTechnicalDescription : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                Allgemeine Attribute
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="packaging"
                        label="Packaging"
                        value={values.packaging ? values.packaging : ''}
                        error={Boolean(touched.packaging && errors.packaging)}
                        fullWidth
                        helperText={touched.packaging && errors.packaging}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearPackaging"
                                    checked={state.clearPackaging ? state.clearPackaging : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="outerPackaging"
                        label="Umverpackung"
                        value={values.outerPackaging ? values.outerPackaging : ''}
                        error={Boolean(touched.outerPackaging && errors.outerPackaging)}
                        fullWidth
                        helperText={touched.outerPackaging && errors.outerPackaging}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearOuterPackaging"
                                    checked={state.clearOuterPackaging ? state.clearOuterPackaging : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="bfx"
                        label="Bfx"
                        value={values.bfx ? values.bfx : ''}
                        error={Boolean(touched.bfx && errors.bfx)}
                        fullWidth
                        helperText={touched.bfx && errors.bfx}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearBfx"
                                    checked={state.clearBfx ? state.clearBfx : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="classGift"
                        label="Kl. 3 Gefahrzettel"
                        value={values.classGift ? values.classGift : ''}
                        error={Boolean(touched.classGift && errors.classGift)}
                        fullWidth
                        helperText={touched.classGift && errors.classGift}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearClassGift"
                                    checked={state.clearClassGift ? state.clearClassGift : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="pointsFactor"
                        label="Points Factor"
                        value={values.pointsFactor ? values.pointsFactor : ''}
                        error={Boolean(touched.pointsFactor && errors.pointsFactor)}
                        fullWidth
                        helperText={touched.pointsFactor && errors.pointsFactor}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearPointsFactor"
                                    checked={state.clearPointsFactor ? state.clearPointsFactor : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="tunnelCode"
                        label="Tunnel Code"
                        value={values.tunnelCode ? values.tunnelCode : ''}
                        error={Boolean(touched.tunnelCode && errors.tunnelCode)}
                        fullWidth
                        helperText={touched.tunnelCode && errors.tunnelCode}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearTunnelCode"
                                    checked={state.clearTunnelCode ? state.clearTunnelCode : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Löschen"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
        ;
}


export default GeneralInfoDanger;
