import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Page components
import Page404 from "./pages/auth/Page404";

//Auth
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Profile from "./pages/auth/Profile";
import ResetProfilePassword from "./pages/auth/ResetProfilePassword";

// Protected routes
import Categories from "./pages/categories/Categories";
import CategoriesContextProvider from "./pages/categories/store/categories-context";
import Category from "./pages/categories/Category";
import CategoryMultiEdit from "./pages/categories/CategoryMultiEdit";
import CategoryImport from "./pages/categories/CategoryImport";
import PlatformsContextProvider from "./pages/platforms/store/platforms-context";
import Platforms from "./pages/platforms/Platforms";
import Platform from "./pages/platforms/Platform";
import PlatformMultiEdit from "./pages/platforms/PlatformMultiEdit";
import PlatformTranslate from "./pages/platforms/PlatformTranslate";
import ProductsContextProvider from "./pages/products/store/products-context";
import Product from "./pages/products/Product";
import ProductMultiEdit from "./pages/products/ProductMultiEdit";
import Products from "./pages/products/Products";
import DynamicSelectContextProvider from "./pages/settings/dynamic-select/store/dynamic-select-context";
import DynamicSelect from "./pages/settings/dynamic-select/Dynamic-Select";
import DynamicSelects from "./pages/settings/dynamic-select/Dynamic-Selects";
import Approvals from "./pages/settings/approvals/Approvals";
import Approval from "./pages/settings/approvals/Approval";
import ApprovalMultiEdit from "./pages/settings/approvals/ApprovalMultiEdit";
import ApprovalsContextProvider from "./pages/settings/approvals/store/approvals-context";
import ApprovalTranslate from "./pages/settings/approvals/ApprovalTranslate";
import Companies from "./pages/companies/Companies";
import Company from "./pages/companies/Company";
import CompaniesContextProvider from "./pages/companies/store/companies-context";
import Users from "./pages/settings/users/Users";
import User from "./pages/settings/users/User";
import UsersContextProvider from "./pages/settings/users/store/users-context";
import MediacenterContextProvider from "./pages/mediacenter/store/mediacenter-context";
import Mediacenter from "./pages/mediacenter/Mediacenter";
import Folder from "./pages/mediacenter/Folder";
import File from "./pages/mediacenter/File";
import FileUpload from "./pages/mediacenter/FileUpload";
import Articles from "./pages/articles/Articles";
import Article from "./pages/articles/Article";
import ArticlesContextProvider from "./pages/articles/store/articles-context";
import ArticleMultiEdit from "./pages/articles/ArticleMultiEdit";
import ArticleTranslate from "./pages/articles/ArticleTranslate";
import ImportsContextProvider from "./pages/settings/import/store/imports-context";
import Imports from "./pages/settings/import/Imports";
import Import from "./pages/settings/import/Import";
import Dangerous from "./pages/settings/dangerous/Dangerous";
import DangerousContextProvider from "./pages/settings/dangerous/store/dangerous-context";
import DangerTranslate from "./pages/settings/dangerous/DangerTranslate";
import Danger from "./pages/settings/dangerous/Danger";
import DangerMultiEdit from "./pages/settings/dangerous/DangerMultiEdit";
import RelationContextProvider from "./pages/relation/store/relation-context";
import Relation from "./pages/relation/Relation";
import AdditionalContextProvider from "./pages/settings/additional/store/additional-context";
import AdditionalSingle from "./pages/settings/additional/Additional-Single";
import Additional from "./pages/settings/additional/Additional";


// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));

const routes = [
    {
        path: "/",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <Default/>,
            },
        ],
    },
    {
        path: "dashboard",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <Default/>,
            },
        ],
    },
    {
        path: "categories",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <AuthGuard><CategoriesContextProvider><Categories/></CategoriesContextProvider></AuthGuard>,
            },
            {
                path: ":id",
                element: <AuthGuard><Category/></AuthGuard>,
            },
            {
                path: "new",
                element: <AuthGuard><Category/></AuthGuard>,
            },
            {
                path: "multi-edit",
                element: <AuthGuard><CategoryMultiEdit/></AuthGuard>,
            },
            {
                path: "import",
                element: <AuthGuard><CategoryImport/></AuthGuard>,
            },
        ],
    },
    {
        path: "products",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <AuthGuard><ProductsContextProvider><Products/></ProductsContextProvider></AuthGuard>,
            },
            {
                path: ":id",
                element: <AuthGuard><Product/></AuthGuard>,
            },
            {
                path: "new",
                element: <AuthGuard><Product/></AuthGuard>,
            },
            {
                path: "multi-edit",
                element: <AuthGuard><ProductMultiEdit/></AuthGuard>,
            },
        ],
    },
    {
        path: "articles",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <AuthGuard><ArticlesContextProvider><Articles /></ArticlesContextProvider></AuthGuard>,
            },
            {
                path: ":id",
                element: <AuthGuard><Article /></AuthGuard>,
            },
            {
                path: ":lang/:id",
                element: <AuthGuard><ArticleTranslate/></AuthGuard>,
            },
            {
                path: "new",
                element: <AuthGuard><Article/></AuthGuard>,
            },
            {
                path: "multi-edit",
                element: <AuthGuard><ArticleMultiEdit/></AuthGuard>,
            },
        ],
    },
    {
        path: "platforms",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element:  <AuthGuard><PlatformsContextProvider><Platforms/></PlatformsContextProvider></AuthGuard>,
            },
            {
                path: ":id",
                element: <AuthGuard><Platform/></AuthGuard>,
            },
            {
                path: ":lang/:id",
                element: <AuthGuard><PlatformTranslate/></AuthGuard>,
            },
            {
                path: "new",
                element: <AuthGuard><Platform/></AuthGuard>,
            },
            {
                path: "multi-edit",
                element: <AuthGuard><PlatformMultiEdit/></AuthGuard>,
            },

        ],
    },
    {
        path: "companies",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element:  <AuthGuard><CompaniesContextProvider><Companies/></CompaniesContextProvider></AuthGuard>,
            },
            {
                path: ":id",
                element: <AuthGuard><Company/></AuthGuard>,
            },

            {
                path: "new",
                element: <AuthGuard><Company/></AuthGuard>,
            },
        ],
    },
    {
        path: "mediacenter",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element:  <AuthGuard><MediacenterContextProvider><Mediacenter/></MediacenterContextProvider></AuthGuard>,
            },
            {
                path: "file/:id",
                element: <AuthGuard><File/></AuthGuard>,
            },
            {
                path: "file/new",
                element: <AuthGuard><FileUpload/></AuthGuard>,
            },
            {
                path: "folder/:id",
                element: <AuthGuard><MediacenterContextProvider><Mediacenter/></MediacenterContextProvider></AuthGuard>,
            },
            {
                path: "folder/new",
                element: <AuthGuard><Folder/></AuthGuard>,
            },
            {
                path: "folder/edit/:id",
                element: <AuthGuard><Folder/></AuthGuard>,
            },
        ],
    },
    {
        path: "settings",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "selects",
                element: <AuthGuard><DynamicSelectContextProvider><DynamicSelects/></DynamicSelectContextProvider></AuthGuard>,
            },
            {
                path: "selects/:id",
                element: <AuthGuard><DynamicSelect/></AuthGuard>,
            },
            {
                path: "benefits",
                element: <AuthGuard><ApprovalsContextProvider><Approvals/></ApprovalsContextProvider></AuthGuard>,
            },
            {
                path: "benefits/:id",
                element: <AuthGuard><Approval/></AuthGuard>,
            },
            {
                path: "benefits/:lang/:id",
                element: <AuthGuard><ApprovalTranslate/></AuthGuard>,
            },
            {
                path: "benefits/new",
                element: <AuthGuard><Approval/></AuthGuard>,
            },
            {
                path: "benefits/multi-edit",
                element: <AuthGuard><ApprovalMultiEdit/></AuthGuard>,
            },
            {
                path: "dangers",
                element: <AuthGuard><DangerousContextProvider><Dangerous/></DangerousContextProvider></AuthGuard>,
            },
            {
                path: "dangers/:id",
                element: <AuthGuard><Danger/></AuthGuard>,
            },
            {
                path: "dangers/:lang/:id",
                element: <AuthGuard><DangerTranslate/></AuthGuard>,
            },
            {
                path: "dangers/new",
                element: <AuthGuard><Danger/></AuthGuard>,
            },
            {
                path: "dangers/multi-edit",
                element: <AuthGuard><DangerMultiEdit/></AuthGuard>,
            },
            {
                path: "additional",
                element: <AuthGuard><AdditionalContextProvider><Additional/></AdditionalContextProvider></AuthGuard>,
            },
            {
                path: "additional/:id",
                element: <AuthGuard><AdditionalSingle/></AuthGuard>,
            },
            {
                path: "additional/new",
                element: <AuthGuard><AdditionalSingle/></AuthGuard>,
            },
            {
                path: "users",
                element: <AuthGuard><UsersContextProvider><Users/></UsersContextProvider></AuthGuard>,
            },
            {
                path: "users/:id",
                element: <AuthGuard><User/></AuthGuard>,
            },
            {
                path: "users/new",
                element: <AuthGuard><User/></AuthGuard>,
            },
            {
                path: "imports",
                element: <AuthGuard><ImportsContextProvider><Imports/></ImportsContextProvider></AuthGuard>,
            },
            {
                path: "imports/:id",
                element: <AuthGuard><Import/></AuthGuard>,
            },
            {
                path: "imports/new",
                element: <AuthGuard><Import/></AuthGuard>,
            },
        ],
    },
    {
        path: "profile",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <AuthGuard><Profile/></AuthGuard>,
            },
        ],
    },
    {
        path: "relation",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element:  <AuthGuard><RelationContextProvider><Relation/></RelationContextProvider></AuthGuard>,
            },
        ],
    },
    {
        path: "*",
        element: <AuthLayout/>,
        children: [
            {
                path: "*",
                element: <Page404/>,
            },
        ],
    },
    {
        path: "auth",
        element: <AuthLayout/>,
        children: [
            {
                path: "login",
                element: <SignIn/>,
            },
            {
                path: "forgot-password",
                element: <ForgotPassword/>,
            },
            {
                path: "reset-password",
                element: <ResetPassword/>,
            },
            {
                path: "reset-profile-password",
                element: <AuthGuard><ResetProfilePassword/></AuthGuard>,
            },
        ],
    },
];

export default routes;
